import React, { Suspense, lazy, useEffect, useState } from "react";
import LayoutContainer from "./screens/layout/Layout";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "./components/Loading";
import { useQuery } from "react-query";
import useAuth from "./data/useAuth";

import History from "./components/History";
import Login from "./screens/auth/login";
import ReferralPayments from "./screens/bbsite/ReferralPayments";
const Dashboard = lazy(() => import("./screens/dashboard"));

// bb site
const Blogs = lazy(() => import("./screens/bbsite/Blogs"));
const AddBlog = lazy(() => import("./screens/bbsite/Add"));
// const BlogDetail = lazy(() => import("./screens/bbsite/Detail"));
const EditBlog = lazy(() => import("./screens/bbsite/EditBlog"));

const ProjectAdd = lazy(() => import("./screens/bbsite/ProjectAdd"));
const ProjectList = lazy(() => import("./screens/bbsite/ProjectList"));

// CRM
const ClientList = lazy(() => import("./screens/crm/ClientList"));

function App() {
	const { authQueryErrorHandler } = useAuth();
	const { data, status } = useQuery("auth", authQueryErrorHandler, {
		onError: (err) => {
			console.log("Error", err);
			localStorage.removeItem("bb_admin_token");
		},
		refetchOnWindowFocus: false,
		retry: false,
	});
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	useEffect(() => {
		if (data !== undefined) {
			if (data?.token) {
				setIsAuthenticated(true);
				setUser(data);
			}
		}
	}, [data]);

	return (
		<>
			{isAuthenticated ? (
				<LayoutContainer user={user}>
					<History />
					<Switch>
						<Suspense fallback={<Loading />}>
							<Route path="/" exact>
								<Dashboard user={user} />
							</Route>
							{/* // bb Site Blog */}
							<Route path="/bbsite/blog/list" exact>
								<Blogs user={user} />
							</Route>
							<Route path="/bbsite/blog/add" exact>
								<AddBlog user={user} />
							</Route>
							<Route path="/bbsite/blog/edit/:id" exact>
								<EditBlog user={user} />
							</Route>
							{/* // bb Site Project */}
							<Route path="/bbsite/project/add" exact>
								<ProjectAdd user={user} />
							</Route>
							<Route path="/bbsite/project/list" exact>
								<ProjectList user={user} />
							</Route>
							<Route path="/bbsite/referral/payments" exact>
								<ReferralPayments user={user} />
							</Route>
							<Route path="/bbsite/crm/client-list" exact>
								<ClientList user={user} />
							</Route>

							<Route path="**" exact>
								<Redirect to="/" />
							</Route>
						</Suspense>
					</Switch>
				</LayoutContainer>
			) : (
				<Switch>
					<Route path="/" exact>
						<Login trying={status} />
					</Route>
					<Route path="**" exact>
						<Redirect to="/" />
					</Route>
				</Switch>
			)}
		</>
	);
}

export default App;
