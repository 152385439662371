import React, { useState, useEffect } from "react";
import { message } from "antd";
import useAuth from "./../../../data/useAuth";

import "./Login.css";
const Login = ({ trying }) => {
	const { authenticate, authenticating } = useAuth();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const LoginHandler = () => {
		if (username === "") {
			message.error("ফোন নাম্বার লিখুন");
		} else if (password === "") {
			message.error("পাসওয়ার্ড দিন");
		} else {
			authenticate({ username, password });
		}
	};

	useEffect(() => {
		const dest = localStorage.getItem("dest");
		if (dest !== null) {
			try {
				const parsedDest = JSON.parse(dest);
				if (!parsedDest.enable) {
					const newDest = JSON.stringify({
						enable: true,
						goto: parsedDest.goto,
					});
					localStorage.setItem("dest", newDest);
				}
			} catch (error) {}
		}
	}, []);

	return (
		<>
			<div className="login__wrapper">
				<div className="left-bg-color" />
				<h1 className="logo__login">বাইনারি বুনন এডমিনিস্ট্রেশন প্যানেল</h1>
				<div className="dialogue">
					{/* <div style={{ paddingTop: 20 }}>Restricted Area </div> */}
					<div>
						<img
							style={{ width: 150, height: "auto" }}
							src="/restricted.png"
							alt=""
						/>
					</div>
				</div>
				<br />

				<div className="login__form-container">
					<form>
						<div>
							<input
								className="login-input-field"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								id="emailOrusername"
								placeholder="username"
							/>
						</div>
						<div>
							<input
								className="login-input-field"
								value={password}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										LoginHandler();
									}
								}}
								onChange={(e) => setPassword(e.target.value)}
								id="password"
								type="password"
								placeholder="পাসওয়ার্ড"
							/>
						</div>
						<button
							disabled={authenticating || trying === "loading"}
							onClick={LoginHandler}
							type="button"
							className="btn-log-in"
						>
							{authenticating || trying === "loading"
								? "প্রবেশের চেস্টা চলছে ..."
								: " প্রবেশ করুন"}
						</button>
					</form>
				</div>
				<div className="footer">
					<a
						className="footer-link"
						target="_blank"
						rel="noreferrer"
						href="https://binarybunon.com/"
					>
						বাইনারি বুনন
					</a>
				</div>
			</div>
		</>
	);
};

export default Login;
