import React, { useState, useEffect } from "react";
import { Table, Button, Popconfirm } from "antd";
import useReferral from "../../data/useReferral";
import moment from "moment";
const ReferralPayments = () => {
	const {
		transections,
		setPerPageTransection,
		setCurrentPageTransection,
		paymentProcess,
	} = useReferral();

	const [trnx, setTrnx] = useState([]);
	const [totalDocuments, setTotalDocuments] = useState(0);

	useEffect(() => {
		if (transections !== undefined) {
			setTrnx(transections.transectios);
			setTotalDocuments(transections.count);
		}
	}, [transections]);

	const columns = [
		{
			title: "Sl",
			dataIndex: "_id",
			key: "_id",
			render: (a, b, c) => c + 1,
		},
		{
			title: "Date",
			dataIndex: "_date",
			key: "_date",
			render: (date) => moment(date).format("L"),
		},
		{
			title: "Narration",
			dataIndex: "head",
			key: "head",
		},
		{
			title: "User",
			dataIndex: "_id",
			key: "_id",
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
		},
		{
			title: "Status",
			dataIndex: "isApproved",
			key: "isApproved",
			render: (status) => (
				<>
					{status === 1 && "Request"}
					{status === 2 && "Success"}
					{status === 3 && "Reject"}
				</>
			),
		},
		{
			title: "Action",
			dataIndex: "_id",
			key: "_id",
			render: (id, row) => (
				<>
					{row.isApproved === 1 && (
						<>
							<Popconfirm
								title="Are You sure ?"
								onConfirm={() => {
									paymentProcess({ id, isApproved: 2 });
								}}
							>
								<Button size="small">Approve</Button>
							</Popconfirm>

							<Popconfirm
								title="Are You sure ?"
								onConfirm={() => {
									paymentProcess({ id, isApproved: 3 });
								}}
							>
								<Button size="small">Reject</Button>
							</Popconfirm>
						</>
					)}
				</>
			),
		},
	];

	return (
		<div>
			<Table
				dataSource={trnx}
				columns={columns}
				pagination={{
					total: { totalDocuments },
					showSizeChanger: true,
					onShowSizeChange: (e, newPageSize) => {
						setPerPageTransection(newPageSize);
					},
					onChange: (e) => {
						setCurrentPageTransection(e);
					},
				}}
				size="small"
				rowKey="_id"
			/>
		</div>
	);
};

export default ReferralPayments;
