import React from "react";
import { Spin } from "antd";
const Loading = () => {
	return (
		<div
			style={{
				width: "80vw",
				height: "80vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Spin />
		</div>
	);
};

export default Loading;
