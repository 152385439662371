import React from "react";
import { Layout, Button, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";

import useAuth from "./../../data/useAuth";

const LayoutContainer = ({ children }) => {
	const { logoutAction } = useAuth();
	function logOutHandler() {
		logoutAction();
	}

	const menu = (
		<Menu>
			<Menu.Item>
				<Link to="/bbsite/blog/add">New Blog</Link>
			</Menu.Item>
			<Menu.Item>
				<Link to="/bbsite/blog/list">Blog List</Link>
			</Menu.Item>
			<Menu.Item>
				<Link to="/bbsite/project/add">New Project</Link>
			</Menu.Item>
			<Menu.Item>
				<Link to="/bbsite/project/list">Project List</Link>
			</Menu.Item>
			<Menu.Item>
				<Link to="/bbsite/referral/payments">Transections</Link>
			</Menu.Item>
		</Menu>
	);

	const crmMenu = (
		<Menu>
			<Menu.Item>
				<Link to="/bbsite/crm/client-list">Client list</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<Layout>
			<header className="__header">
				<Dropdown overlay={menu} placement="bottomLeft" arrow>
					<Button>Site Setting</Button>
				</Dropdown>
				<Dropdown overlay={crmMenu} placement="bottomLeft" arrow>
					<Button>CRM</Button>
				</Dropdown>
				<Button onClick={logOutHandler}>Logout</Button>
			</header>

			<main>{children}</main>
		</Layout>
	);
};

export default LayoutContainer;
