import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
const History = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, [window]);

	const { pathname } = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (pathname === "/") {
			// check If has to Redirect or Not
			const dest = localStorage.getItem("dest");
			if (dest !== null) {
				try {
					const parsedDest = JSON.parse(dest);
					if (parsedDest.enable) {
						history.push(parsedDest.goto);
					}
				} catch (error) {}
			}
		} else {
			localStorage.setItem(
				"dest",
				JSON.stringify({ enable: false, goto: pathname })
			);
		}
	}, [pathname, history]);
	return <React.Fragment></React.Fragment>;
};

export default History;
