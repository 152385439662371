import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "./AxiosProvider";
import { message } from "antd";
const useReferral = () => {
	const axios = useAxios();

	const [processd, setProcessd] = useState(false);
	const queryClient = useQueryClient();

	const paymentReqeustApproveRequest = async (d) => {
		const { data } = await axios.post(
			"/affiliate/admin-payment-request-approve",
			d
		);
		return data;
	};

	const referralTransections = async (
		perPageTransection,
		currentPageTransection
	) => {
		const { data } = await axios.get("/affiliate/admin-all-transections", {
			params: {
				perPageTransection,
				currentPageTransection,
			},
		});
		return data;
	};

	const [perPageTransection, setPerPageTransection] = useState(10);
	const [currentPageTransection, setCurrentPageTransection] = useState(1);

	const { data: transections } = useQuery(
		["referral-transections", perPageTransection, currentPageTransection],
		() => referralTransections(perPageTransection, currentPageTransection),
		{
			refetchOnWindowFocus: false,
		}
	);

	const { mutate: paymentProcess } = useMutation(paymentReqeustApproveRequest, {
		onSuccess: (res) => {
			setProcessd(true);
			message.success(res);
			queryClient.invalidateQueries([
				"referral-transections",
				perPageTransection,
				currentPageTransection,
			]);
		},
		onError: (err) => {
			setProcessd(false);
			message.error(err.response.data.message);
		},
	});

	return {
		processd,
		setProcessd,
		transections,
		setPerPageTransection,
		setCurrentPageTransection,
		paymentProcess,
	};
};

export default useReferral;
